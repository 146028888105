import React from "react"
import { useSelector } from "react-redux"

import { Field } from "formik"

import { Row, Col, Form } from "react-bootstrap"

function PropertyRefField(props) {
    // The selector to retrieve the current property details form state.
    const makeEnquiryProperty = useSelector(
        (state) => state.makeEnquiryModal.property,
    )
    const requestViewingProperty = useSelector(
        (state) => state.requestViewingModal.property,
    )

    return (
        <>
            {/* Property Ref Field */}
            <Form.Group>
                <Row>
                    <Form.Label
                        as={Col}
                        xs="auto"
                        htmlFor="address"
                        className="my-auto pe-0 me-0"
                    >
                        <span className="text-sm text-wjlightgrey text-semi-bold">
                            Property address
                        </span>
                    </Form.Label>
                    <Col xs={12} className="px-0 mx-0 ms-3 ms-2">
                        <Field
                            as="textarea"
                            id="address"
                            name="address"
                            aria-label="address"
                            type="text"
                            readOnly
                            className="form-control-plaintext text-sm text-wjlightgrey "
                            value={
                                makeEnquiryProperty?.display_address
                                    ? makeEnquiryProperty.display_address
                                    : requestViewingProperty?.display_address
                                    ? requestViewingProperty.display_address
                                    : ""
                            }
                        />
                    </Col>
                    {props.formik.errors.address &&
                    props.formik.touched.address ? (
                        <p className="text-danger text-normal text-sm">
                            {props.formik.errors.address}
                        </p>
                    ) : null}
                </Row>
            </Form.Group>
            {/* Property Ref Field End */}
        </>
    )
}

export default PropertyRefField
